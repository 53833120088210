.cv {
  border-radius: 1.25vw;
  box-sizing: border-box;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, -50%, 0) scale(0.9);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
  width: 45.625vw;
}
.area {
  box-sizing: border-box;
  height: calc(70vh);
  position: relative;
}
.drop {
  height: 100%;
  width: 100%;
}
.drop > input {
  left: -99999px;
  position: absolute;
}
.drop > label {
  border-radius: 1.25vw;
  border: 0.260416666666667vw dashed var(--Icon-Grey-Icon-Grey-80);
  background: var(--White-White-40);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 6.145833333333333vw;
  width: 100%;
  transition: border-color var(--hover-speed) ease;
}
.drop > label.hasError {
  border-color: var(--Red);
}
.big {
  font-size: 1.666666666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 1.25vw;
}
.big.portrait {
  display: none;
}
.big > span {
  color: var(--Blue-Elemental-Blue-Elemental-100);
}
.tip {
  color: var(--Dark-Moonless-Mystery-60);
  font-size: 1.041666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0.3125vw;
  text-align: center;
  overflow-wrap: break-word;
}
.tip.tipRed {
  color: var(--Red);
}
.tip:last-child {
  margin-bottom: 0;
}
.tip > b {
  font-weight: 500;
}
.tip.error {
  color: var(--Red);
  position: absolute;
  top: 4.84375vw;
}
.progress {
  border-radius: 1.25vw;
  border: 0.260416666666667vw dashed var(--Icon-Grey-Icon-Grey-80);
  background: var(--White-White-40);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 6.145833333333333vw;
  width: 100%;
}
.fileInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.041666666666667vw;
}
.fileData {
  flex: 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.fileIcon {
  aspect-ratio: 1 / 1;
  flex: 0 0 3.333333333333333vw;
  margin-right: 0.833333333333333vw;
}
.fileIcon > svg {
  display: block;
  width: 100%;
}
.fileName {
  flex: 0 1 100%;
  font-size: 1.666666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  word-break: break-all;
}
.fileSize {
  color: var(--Dark-Moonless-Mystery-40);
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  flex: 0 0 auto;
  margin-left: 1.25vw;
}
.fileLoadingBar {
  background-color: var(--Icon-Grey-Icon-Grey-40);
  border-radius: 200px;
  height: 0.416666666666667vw;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.fileLoadingStatus {
  animation: fileLoading 1s ease infinite;
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  border-radius: 200px;
  height: 100%;
  width: 40%;
}
.fileViewer {
  background-color: white;
  border-radius: 1.25vw;
  box-sizing: border-box;
  padding: 2.083333333333333vw;
  margin: 0 auto;
  position: relative;
  width: 60%;
}
.fileViewerTitle {
  font-size: 1.666666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 0.833333333333333vw;
}
.fileViewerText > p {
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
}
.fileViewerFile {
  background-color: var(--Light-Bg);
  border-radius: 0.625vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.916666666666667vw;
  padding: 0.625vw 0.416666666666667vw;
}
.fileViewerIcon {
  flex: 0 0 3.333333333333333vw;
  width: 3.333333333333333vw;
  margin-right: 0.520833333333333vw;
}
.fileViewerData {
  flex: 0 1 100%;
}
.fileViewerName {
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.208333333333333vw;
  word-break: break-all;
}
.fileViewerSize {
  color: var(--Dark-Moonless-Mystery-40);
  font-size: 1.041666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
.fileViewerFile > button {
  aspect-ratio: 1 / 1;
  flex: 0 0 2.083333333333333vw;
  margin-left: 1.25vw;
  width: 2.083333333333333vw;
}
.fileViewerFile > button > svg > path {
  fill: var(--Icon-Grey);
  transition: fill var(--hover-speed) ease;
}

.active.shown .cv {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, -50%, 0) scale(1);
  transition-delay: 0.15s;
}

@keyframes fileLoading {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(250%, 0, 0);
  }
}

@media (pointer: fine) {
  .drop > label:hover {
    border-color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .fileViewerFile > button:hover > svg > path {
    fill: var(--Red);
  }
}

@media (orientation: portrait) {
  .cv {
    box-sizing: border-box;
    height: calc((100dvh - 61px - (20px + 16px + 20px)) * 0.48);
    margin: 0;
    top: 50%;
    width: 100%;
  }

  .area {
    height: 100%;
  }

  .drop > label {
    border-radius: 24px;
    border-width: 2px;
    padding: 24px;
  }
  .big {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .big.landscape {
    display: none;
  }
  .big.portrait {
    display: block;
  }
  .tip {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .tip.error {
    top: 24px;
  }
  .progress {
    border-radius: 24px;
    border-width: 2px;
    padding: 24px;
  }
  .fileInfo {
    margin-bottom: 20px;
  }
  .fileIcon {
    flex: 0 0 40px;
    margin-right: 8px;
  }
  .fileName {
    font-size: 14px;
  }
  .fileSize {
    font-size: 12px;
    margin-left: 12px;
  }
  .fileLoadingBar {
    height: 4px;
  }
  .fileViewer {
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    padding: 24px;
    width: 100%;
  }
  .fileViewerTitle {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .fileViewerText > p {
    font-size: 14px;
  }
  .fileViewerFile {
    border-radius: 12px;
    margin-top: 40px;
    padding: 12px 8px;
  }
  .fileViewerIcon {
    flex: 0 0 48px;
    width: 48px;
    margin-right: 8px;
  }
  .fileViewerName {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .fileViewerSize {
    font-size: 12px;
  }
  .fileViewerFile > button {
    flex: 0 0 36px;
    margin-left: 16px;
    width: 36px;
  }
}
