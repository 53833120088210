.question {
  color: var(--Dark-Moonless-Mystery-100);
  display: flex;
  height: calc(
    100dvh - (6.25vw + 3.28125vw) -
      (9.166666666666667vw + 3.28125vw + 3.28125vw)
  );
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3.75vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  pointer-events: none !important;
  position: absolute;
  top: calc(6.25vw + 3.28125vw);
  width: 59.166666666666667vw;
  transform-origin: 50% 0%;
  transition: transform var(--move-speed) ease;
  user-select: none;
  z-index: 1;
}
.confirmation {
  line-height: 1.7;
  width: 44.270833333333333vw;
}
.question > p > span {
  opacity: 0;
  animation: question 0.5s linear forwards;
}
.recording.question {
  transform: scale(0.75) translate3d(0, -30%, 0);
}

@media (orientation: portrait) {
  .question {
    font-size: 40px;
    height: calc(100dvh - 61px - 91px - 152px);
    top: 91px;
    width: calc(100% - 48px);
  }
  .recording.question {
    transform: scale(1) translate3d(0, -20%, 0);
  }
}

@keyframes question {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
