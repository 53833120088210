.screenLeftCircle {
  background-color: white;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translate3d(-50%, -50%, 0) scale(0.75);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
  width: 75.2283105022831%;
}
.screenVisual {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, -50%, 0) scale(0.9);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
  width: 45.625vw;
}

.active.shown .screenLeftCircle {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition-delay: 0.15s;
}
.active.shown .screenVisual {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, -50%, 0) scale(1);
  transition-delay: 0.15s;
}

@media (orientation: portrait) {
  .screenLeftCircle {
    top: 50%;
    object-fit: contain;
    object-position: 50% 75%;
  }
  .screenVisual {
    aspect-ratio: 876 / 659;
    display: block;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    top: 50%;
    width: 100%;
    max-width: 480px;
  }
  .screenLeftContent {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 480px;
  }
}
