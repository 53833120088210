.footer {
  bottom: 3.28125vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9.166666666666667vw;
  position: absolute;
  transform: translate3d(0, calc(100% + 3.28125vw), 0);
  width: calc(100% - 10.416666666666667vw);
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}

/* Temporary Hide Buttons */
.footer {
  justify-content: center;
}
.footer > button:not(.confirm) {
  display: none;
}

.button {
  color: var(--Dark-Moonless-Mystery-100);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.041666666666667vw;
  min-width: 12.5vw;
  transition: color var(--hover-speed) ease;
}
.button:last-child {
  justify-content: flex-end;
}
.button:first-child::before {
  aspect-ratio: 1 / 1;
  background: rgba(80, 132, 233, 0.05);
  border-radius: 50%;
  content: "";
  left: -5.208333333333333vw;
  pointer-events: none;
  position: absolute;
  bottom: -3.28125vw;
  transform: scale(0) translate3d(-50%, 50%, 0);
  transform-origin: 0% 100%;
  width: 50vw;
  transition: transform var(--move-speed) ease;
}
.button > svg {
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  display: block;
  width: 2.5vw;
}
.button > svg > path {
  stroke: var(--Icon-Grey);
  transition: fill var(--hover-speed) ease, stroke var(--hover-speed) ease;
}
.button > span {
  margin-left: 0.625vw;
  position: relative;
}
.button:last-child > span {
  margin-left: 0;
  margin-right: 0.625vw;
}

.record {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}
.record > button {
  aspect-ratio: 1 / 1;
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85.2272727272727%;
  transition: background-color var(--hover-speed) ease,
    color var(--hover-speed) ease;
}
.record > button.disabled {
  background-color: var(--Blue-Elemental-Blue-Elemental-80);
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}
.record > button > svg {
  width: 38.4615384615385%;
  transition: opacity var(--hover-speed) ease;
}
.record > button.disabled > svg {
  opacity: 0.8;
}
.record > button.recording {
  background-color: var(--Dark-Moonless-Mystery-100);
}
.record > button.recording > svg > rect {
  stroke: white;
  transition: stroke var(--hover-speed) ease;
}
.time {
  color: var(--Dark-Moonless-Mystery-100);
  text-align: center;
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  position: absolute;
  top: -3.333333333333333vw;
  width: 100%;
}
.timeNotice {
  color: var(--Red);
  text-align: center;
  font-size: 0.9375vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  left: 50%;
  position: absolute;
  top: -8.333333333333333vw;
  transform: translate3d(-50%, 0, 0);
  width: 16.666666666666667vw;
}
.circle {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.circle > svg > circle {
  transition: opacity var(--hover-speed) ease;
}
.circle > svg > .arc {
  stroke: var(--Blue-Elemental-Blue-Elemental-100);
  stroke-dasharray: 552.92;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke var(--hover-speed) ease,
    stroke-dashoffset var(--hover-speed) ease;
}
.circle.red > svg > circle {
  opacity: 0;
}
.circle.red > svg > .arc {
  animation: pulseRed 2s linear infinite;
  opacity: 1;
  stroke: var(--Red);
  stroke-dasharray: none !important;
  stroke-dashoffset: unset !important;
}

.active.footer {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.5s;
}

.confirm {
  border-radius: 0.729166666666667vw;
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  box-sizing: border-box;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
  padding: 0 1.666666666666667vw;
  min-width: 6.770833333333333vw;
  transition: background-color var(--hover-speed) ease,
    border-color var(--hover-speed) ease, color var(--hover-speed) ease;
}
.confirm.disabled {
  background-color: var(--Blue-Elemental-Blue-Elemental-80);
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
}

@media (pointer: fine) {
  .button:first-child:hover::before {
    transform: scale(1) translate3d(-50%, 50%, 0);
  }
  .button:hover {
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .button:hover > svg > path {
    stroke: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .record > button:hover {
    background-color: var(--Mic-hover);
  }
  .record > button.recording:hover {
    background-color: var(--Dark-Moonless-Mystery-100);
  }
  .record > button.recording:hover > svg > rect {
    stroke: var(--Red);
  }

  .confirm:hover {
    background-color: var(--Button-hover);
  }
}

@media (orientation: portrait) {
  .footer {
    bottom: 14px;
    height: 120px;
    transform: translate3d(0, calc(100% + 14px), 0);
    width: calc(100% - 48px);
  }
  .button {
    aspect-ratio: 1 / 1;
    min-width: 60px;
    width: 60px;
  }
  .button > svg {
    width: 40px;
  }
  .button > span {
    display: none;
  }

  .record > button {
    width: 100px;
  }
  .timeNotice {
    font-size: 16px;
    top: -120px;
    width: 328px;
  }
  .time {
    font-size: 16px;
    top: -40px;
  }
  .confirm {
    border-radius: 12px;
    font-size: 16px;
    height: 52px;
    padding: 0 16px;
    min-width: 130px;
  }
}

@keyframes pulseRed {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  75% {
    transform: scale(1.05);
  }
}
