.checkbox {
  margin-top: 1.666666666666667vw;
}

.checkbox > input {
  position: absolute;
  top: -999999px;
}

.checkbox > input + label {
  cursor: pointer;
  display: block;
  line-height: 1.770833333333333vw;
  padding-left: 2.604166666666667vw;
  position: relative;
}

.checkbox > input + label::before {
  aspect-ratio: 1 / 1;
  border-radius: 0.416666666666667vw;
  border: 1px solid var(--Icon-Grey);
  background-color: white;
  box-sizing: border-box;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 1.770833333333333vw;
}

.checkbox > input:checked + label::before {
  border-color: var(--Blue-Elemental-Blue-Elemental-100);
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNSAxNC41TDE0LjUgMjAuNUwyMy41IDExLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (orientation: portrait) {
  .checkbox {
    margin-top: 24px;
  }

  .checkbox > input + label {
    line-height: 24px;
    padding-left: 40px;
  }

  .checkbox > input + label::before {
    border-radius: 6px;
    width: 24px;
  }
}
