.form {
  border-radius: 1.25vw;
  box-sizing: border-box;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transform: translate3d(0, -50%, 0) scale(0.9);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
  width: 45.625vw;
}
.content {
  border-radius: 1.25vw;
  background: #fff;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 2.916666666666667vw 2.083333333333333vw;
  position: relative;
  width: 73%;
}
.content.loading {
  pointer-events: none;
}
.loadingState {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity var(--hover-speed) ease;
  z-index: 1;
}
.loadingState > svg {
  width: 3.333333333333333vw;
}
.content.loading .loadingState {
  opacity: 1;
  pointer-events: auto;
}
.warn {
  align-self: center;
  flex: 0 0 1.25vw;
  margin-right: 0.520833333333333vw;
}
.user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2.5vw;
}
.userInfo {
  align-self: center;
  flex: 0 1 100%;
}
.userName {
  font-size: 1.041666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
.userEmail {
  color: var(--Dark-Moonless-Mystery-80);
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
.user > button {
  aspect-ratio: 1 / 1;
  border-radius: 0.729166666666667vw;
  border: 1px solid var(--Icon-Grey-Icon-Grey-60);
  background-color: white;
  box-sizing: border-box;
  color: var(--Dark-Moonless-Mystery-80);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
  transition: background-color var(--hover-speed) ease,
    border-color var(--hover-speed) ease, color var(--hover-speed) ease;
}
.user > button > svg {
  width: 40%;
}
.user > button > svg > path {
  fill: var(--Dark-Moonless-Mystery-90);
  transition: fill var(--hover-speed) ease;
}
.job {
  border-top: 2px solid var(--Icon-Grey-Icon-Grey-20);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 1.666666666666667vw 0;
  width: 100%;
}
.job:first-child {
  border-top: 0;
  padding-top: 0;
}
.job:last-child {
  padding-bottom: 0;
}
.jobInfo {
  align-self: center;
  text-align: left;
  flex: 0 1 100%;
}
.jobCompany {
  font-size: 1.041666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}
.jobPeriod {
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.416666666666667vw;
}
.edit {
  flex: 0 0 5.208333333333333vw;
  border: 1px solid var(--Icon-Grey-Icon-Grey-60);
  border-radius: 0.729166666666667vw;
  background-color: white;
  box-sizing: border-box;
  color: var(--Dark-Moonless-Mystery-80);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
  margin-left: 1.25vw;
  padding: 0 1.666666666666667vw;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--hover-speed) ease;
}

.active.shown .form {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, -50%, 0) scale(1);
  transition-delay: 0.15s;
}

@media (pointer: fine) {
  .user > button:hover {
    background-color: white;
    border-color: var(--Blue-Elemental-Blue-Elemental-100);
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .user > button:hover > svg > path {
    fill: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .job:hover .edit {
    opacity: 1;
  }
}

@media (orientation: portrait) {
  .form {
    box-sizing: border-box;
    height: calc((100dvh - 61px - (20px + 16px + 20px)) * 0.48);
    margin: 0;
    top: 50%;
    width: 100%;
  }
  .content {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    padding: 16px;
    width: 100%;
  }
  .loadingState > svg {
    width: 48px;
  }
  .warn {
    flex: 0 0 16px;
    margin-right: 8px;
  }
  .user {
    margin-bottom: 16px;
  }
  .userName {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6;
  }
  .userEmail {
    font-size: 12px;
    line-height: 1.2;
  }
  .user > button {
    border-radius: 12px;
    font-size: 16px;
    height: 40px;
  }
  .job {
    border-top: 1px solid var(--Icon-Grey-Icon-Grey-60);
    box-sizing: border-box;
    height: 65px;
    padding: 8px 0;
  }
  .jobCompany {
    color: var(--Dark-Moonless-Mystery-90);
    font-size: 12px;
    line-height: 1.6;
  }
  .jobPeriod {
    color: var(--Dark-Moonless-Mystery-60);
    font-size: 12px;
    margin-top: 0;
  }
  .edit {
    display: none;
  }

  .active.shown .form {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, -50%, 0) scale(1);
    transition-delay: 0.15s;
  }
}
