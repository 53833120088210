.OverlayOpen {
  animation: openOverlay 0.5s ease-out forwards;
}

.OverlayClosed {
  animation: closeOverlay 0.35s ease-out forwards;
}

.ModalOpen {
  animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.35s ease-out forwards;
}

@keyframes openOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.overlay {
  background: var(--Dark-Moonless-Mystery-10);
  backdrop-filter: blur(10px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.popup {
  border-radius: 1.25vw;
  box-sizing: border-box;
  background: white;
  left: 50%;
  margin-left: -14.375vw;
  padding: 3.333333333333333vw 2.083333333333333vw 2.083333333333333vw
    2.083333333333333vw;
  position: fixed;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 28.75vw;
  z-index: 2100;
}
.popup > p {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 1.666666666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 3.333333333333333vw;
  text-align: center;
}
.buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 -0.208333333333333vw;
}
.buttons > * {
  flex: 0 1 100%;
  margin: 0 0.208333333333333vw;
  white-space: nowrap;
}

@media (orientation: portrait) {
  .ModalOpen {
    animation: openModalPortrait 0.5s ease-out forwards;
  }

  .ModalClosed {
    animation: closeModalPortrait 0.35s ease-out forwards;
  }

  .overlay {
    backdrop-filter: blur(5px);
  }
  .popup {
    border-radius: 16px;
    bottom: 52px;
    left: 24px;
    margin-left: 0;
    padding: 32px 16px 16px 16px;
    right: 24px;
    top: auto;
    transform: translate3d(0, 0, 0);
    width: auto;
  }
  .popup > p {
    font-size: 24px;
    margin-bottom: 32px;
  }
  .buttons {
    margin: 0 -4px;
  }
  .buttons > * {
    margin: 0 4px;
  }
}

@keyframes openModalPortrait {
  0% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes closeModalPortrait {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }
}
