.loading {
  flex-direction: column;
  z-index: 100;
}

.lottie {
  aspect-ratio: 588 / 498;
  position: relative;
  transform-origin: 50% 50%;
  width: 30.625vw;
}

.tip {
  animation: tip 5s ease-in-out infinite;
  animation-play-state: paused;
  left: 45%;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -100%, 0);
  transform-origin: 50% 50%;
  width: 25vw;
}
.tipText {
  animation: tipText 5s ease-in-out infinite;
  animation-play-state: paused;
  background-color: white;
  border-radius: 2.083333333333333vw 2.083333333333333vw 2.083333333333333vw 0;
  box-shadow: 10px 12px 35px 0px rgba(17, 30, 54, 0.01),
    41px 48px 63px 0px rgba(17, 30, 54, 0.04),
    93px 108px 86px 0px rgba(17, 30, 54, 0.03),
    166px 192px 101px 0px rgba(17, 30, 54, 0.02),
    259px 300px 111px 0px rgba(17, 30, 54, 0.01);
  box-sizing: border-box;
  padding: 1.875vw;
  transform-origin: 0% 100%;
}
.tip svg {
  animation: tipSvg 5s ease-in-out infinite;
  animation-play-state: paused;
  aspect-ratio: 1 / 1;
  bottom: 100%;
  left: 100%;
  position: absolute;
  transform: translate3d(-25%, 25%, 0);
  width: 2.604166666666667vw;
}

.loader {
  width: 4vw;
}

/* Animation Default State */
.lottie {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.loader {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.message {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Appear Animation */
.active .lottie {
  opacity: 1;
  transform: scale(1);
}
.active .loader {
  opacity: 1;
  transition-delay: 0.25s;
}
.active .message {
  opacity: 1;
  transition-delay: 0.25s;
}
.active .tip,
.active .tipText,
.active .tip svg {
  animation-play-state: running;
}

/* Hide Animation */
.hide .lottie {
  opacity: 0;
  transform: scale(1.1);
}
.hide .loader {
  opacity: 0;
  transition-delay: 0s;
}
.hide .message {
  opacity: 0;
  transition-delay: 0s;
}
.hide .tip,
.hide .tipText,
.hide .tip svg {
  animation-play-state: running;
}

@media (orientation: portrait) {
  .lottie {
    width: calc(75vw);
  }
  .tip {
    width: 45vw;
  }
  .tipText {
    border-radius: 16px 16px 16px 0;
    font-size: 14px;
    padding: 16px;
  }
  .tip svg {
    width: 20px;
  }
  .loader {
    width: 32px;
  }
}

@keyframes tip {
  0% {
    transform: translate3d(0, -100%, 0) rotate(-2deg);
  }
  20% {
    transform: translate3d(0, -100%, 0) rotate(2deg);
  }
  40% {
    transform: translate3d(0, -100%, 0) rotate(-2deg);
  }
  60% {
    transform: translate3d(0, -100%, 0) rotate(2deg);
  }
  80% {
    transform: translate3d(0, -100%, 0) rotate(-2deg);
  }
  100% {
    transform: translate3d(0, -100%, 0) rotate(2deg);
  }
}

@keyframes tipText {
  0%,
  5%,
  95%,
  100% {
    transform: scale(0);
  }
  10%,
  90% {
    transform: scale(1);
  }
}

@keyframes tipSvg {
  0%,
  7.5%,
  94%,
  100% {
    opacity: 0;
  }
  12.5%,
  89% {
    opacity: 1;
  }
}
