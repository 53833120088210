.header {
  box-sizing: border-box;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.666666666666667vw 0;
}
.content > svg {
  aspect-ratio: 652 / 168;
  display: block;
  position: relative;
  width: 14.84375vw;
}
.content > button {
  color: var(--Dark-Moonless-Mystery-100);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.041666666666667vw;
  position: relative;
  transition: color var(--hover-speed) ease;
}
.content > button > svg {
  aspect-ratio: 1 / 1;
  box-sizing: border-box;
  display: block;
  padding: 0.364583333333333vw;
  width: 2.5vw;
}
.content > button > svg > path {
  fill: var(--Icon-Grey);
  stroke: var(--Icon-Grey);
  transition: fill var(--hover-speed) ease, stroke var(--hover-speed) ease;
}
.content > button > span {
  margin-left: 0.625vw;
  position: relative;
}

@media (pointer: fine) {
  .content > button:hover {
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .content > button:hover > svg > path {
    fill: var(--Blue-Elemental-Blue-Elemental-100);
    stroke: var(--Blue-Elemental-Blue-Elemental-100);
  }
}

@media (orientation: portrait) {
  .header {
    border-bottom: 1px solid var(--Border);
  }
  .content {
    padding: 10px 0;
  }
  .content > svg {
    width: 176px;
  }
  .content > button {
    margin-right: -5.82812px;
  }
  .content > button > svg {
    padding: 5.82812px;
    width: 40px;
  }
  .content > button > span {
    display: none;
  }
}
