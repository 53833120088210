.screenRightContent {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translate3d(-7.5%, 0, 0);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
}

.screenText {
  font-size: 0.9375vw;
  min-height: 10vw;
}
.screenText > p {
  margin-bottom: 0.9375vw;
}
.screenText > p:last-child {
  margin-bottom: 0;
}

.active.shown.screenRightContent {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.15s;
}

@media (orientation: portrait) {
  .screenText {
    font-size: 14px;
    min-height: 0;
  }
  .screenText > p {
    margin-bottom: 14px;
  }
}
