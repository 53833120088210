.OverlayOpen {
  animation: openOverlay 0.5s ease-out forwards;
}

.OverlayClosed {
  animation: closeOverlay 0.35s ease-out forwards;
}

.ModalOpen {
  animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.35s ease-out forwards;
}

@keyframes openOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.overlay {
  background: var(--Dark-Moonless-Mystery-10);
  backdrop-filter: blur(10px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.popup {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100dvh - 12.5vw);
  left: 50%;
  margin-left: -16.666666666666667vw;
  position: fixed;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 33.333333333333333vw;
  z-index: 2100;
}
.popupContent {
  background-color: white;
  border-radius: 1.25vw;
  box-sizing: border-box;
  padding: 2.083333333333333vw 0.9375vw 2.083333333333333vw;
  flex: 0 1 100%;
}
.popupScroll {
  box-sizing: border-box;
  height: calc(
    100dvh - 12.5vw - 2.083333333333333vw - 2.083333333333333vw -
      3.333333333333333vw - 1.25vw
  );
  overflow: auto;
  padding: 0.833333333333333vw 1.145833333333333vw 0.833333333333333vw;
  position: relative;
}
.popupScroll::-webkit-scrollbar {
  width: 0.416666666666667vw;
}
.popupScroll::-webkit-scrollbar-track {
  background: var(--Icon-Grey-Icon-Grey-40);
  border-radius: 100px;
}
.popupScroll::-webkit-scrollbar-thumb {
  background: var(--Blue-Elemental-Blue-Elemental-100);
  border-radius: 100px;
}
.popupScroll::-webkit-scrollbar-thumb:hover {
  background: var(--Blue-Elemental-Blue-Elemental-80);
}
.popupFooter {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25vw;
}
.popupFooter > * {
  flex: 0 0 calc(50% - 0.416666666666667vw);
}

.formLine {
  margin-bottom: 1.25vw;
}
.formLine:last-child {
  margin-bottom: 0;
}
.formLine.split {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.formLine.split > * {
  flex: 0 0 calc(50% - 0.625vw);
}
.spacer {
  height: 1.25vw;
}
.hr {
  border-top: 2px solid var(--Icon-Grey-Icon-Grey-20);
  margin-top: 0.416666666666667vw;
  padding-top: 1.666666666666667vw;
}
.input {
  position: relative;
}
.input > label {
  background-color: white;
  color: var(--Dark-Moonless-Mystery-60);
  font-size: 0.729166666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  left: 1.041666666666667vw;
  padding: 0 0.208333333333333vw;
  position: absolute;
  top: 0;
  transform: translate3d(0, -50%, 0);
  transition: color var(--hover-speed) ease;
}
.input > input {
  border: none;
  border: 1px solid var(--Icon-Grey-Icon-Grey-60);
  border-radius: 0.729166666666667vw;
  background-image: none;
  background-color: transparent;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: var(--Dark-Moonless-Mystery-100);
  font-family: "Montserrat", sans-serif;
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  padding: 0 1.041666666666667vw;
  margin: 0;
  outline: 1px solid transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.916666666666667vw;
  transition: border-color var(--hover-speed) ease,
    outline var(--hover-speed) ease;
}
.input > input::placeholder {
  color: var(--Dark-Moonless-Mystery-20);
}
.input > input.hasError {
  border-color: var(--Red);
  outline-color: var(--Red);
}
.input > input.hasError + label {
  color: var(--Red);
}
.input > input:focus {
  border-color: var(--Blue-Elemental-Blue-Elemental-100);
  outline-color: var(--Blue-Elemental-Blue-Elemental-100);
}
.input > input:focus + label {
  color: var(--Blue-Elemental-Blue-Elemental-100);
}
.input > input:-webkit-autofill,
.input > input:-webkit-autofill:hover,
.input > input:-webkit-autofill:focus,
.input > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.error {
  color: var(--Red);
  font-size: 0.729166666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.416666666666667vw;
  padding-left: 1.09375vw;
}

input[type="date"] {
  color: transparent !important;
}
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  background-color: transparent !important;
  color: transparent !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
.dateValue {
  box-sizing: border-box;
  border: 1px solid transparent;
  color: var(--Dark-Moonless-Mystery-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  height: 2.916666666666667vw;
  padding: 0 1.041666666666667vw;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.dateValue.empty {
  color: var(--Dark-Moonless-Mystery-20);
}
.dateValueIcon {
  aspect-ratio: 1 / 1;
  width: 1.25vw;
}
.dateValueIcon > svg {
  width: 100%;
}
.dateValueIcon > svg > path {
  fill: var(--Dark-Moonless-Mystery-30);
}
.input.disabled {
  pointer-events: none;
}
.input.disabled > input {
  border-color: var(--Icon-Grey-Icon-Grey-40);
}
.input.disabled > input + label {
  color: var(--Dark-Moonless-Mystery-40);
}
.input.disabled .dateValue {
  color: var(--Dark-Moonless-Mystery-20);
}
.input.disabled .dateValueIcon > svg > path {
  fill: var(--Dark-Moonless-Mystery-20);
}

.checkbox > input {
  position: absolute;
  top: -999999px;
}

.checkbox > input + label {
  cursor: pointer;
  display: block;
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.666666666666667vw;
  padding-left: 2.5vw;
  position: relative;
}

.checkbox > input + label::before {
  aspect-ratio: 1 / 1;
  border-radius: 0.416666666666667vw;
  border: 1px solid var(--Icon-Grey);
  background-color: white;
  box-sizing: border-box;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 1.666666666666667vw;
}

.checkbox > input:checked + label::before {
  border-color: var(--Blue-Elemental-Blue-Elemental-100);
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNSAxNC41TDE0LjUgMjAuNUwyMy41IDExLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (pointer: fine) {
  .input:hover .dateValueIcon > svg > path {
    fill: var(--Blue-Elemental-Blue-Elemental-100);
  }
}

@media (orientation: portrait) {
  .ModalOpen {
    animation: openModalPortrait 0.5s ease-out forwards;
  }

  .ModalClosed {
    animation: closeModalPortrait 0.35s ease-out forwards;
  }

  .overlay {
    backdrop-filter: blur(5px);
    z-index: 3000;
  }
  .popup {
    left: 0;
    height: calc(100dvh - 75px - 40px);
    margin-left: 0;
    padding: 0 10px;
    top: 75px;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 3100;
  }
  .popupContent {
    border-radius: 10px;
    padding: 21px 7px;
  }
  .popupScroll {
    height: calc(100dvh - 75px - 40px - 42px - 12px - 52px);
    padding: 8px 13px;
  }
  .popupScroll::-webkit-scrollbar {
    width: 4px;
  }
  .popupFooter {
    margin-top: 12px;
  }
  .popupFooter > * {
    flex: 0 0 calc(50% - 4px);
  }

  .formLine {
    margin-bottom: 24px;
  }
  .formLine.deviceNoSplit {
    display: block;
  }
  .formLine.deviceNoSplit > * {
    margin-bottom: 24px;
  }
  .formLine.deviceNoSplit:last-child {
    margin-bottom: 0;
  }
  .formLine.split > * {
    flex: 0 0 calc(50% - 8px);
  }
  .spacer {
    height: 24px;
  }
  .hr {
    border-top: 1px solid var(--Icon-Grey-Icon-Grey-60);
    margin-top: 8px;
    padding-top: 32px;
  }

  .input > label {
    font-size: 12px;
    left: 12px;
    padding: 0 4px;
  }
  .input > input {
    border-radius: 8px;
    font-size: 14px;
    padding: 0 16px;
    height: 40px;
  }
  .error {
    font-size: 10px;
    margin-top: 4px;
    padding-left: 17px;
  }
  input[type="date"] {
    font-size: 1px;
  }
  .dateValue {
    font-size: 14px;
    height: 40px;
    padding: 0 8px 0 16px;
  }
  .dateValueIcon {
    width: 24px;
  }

  .checkbox > input + label {
    font-size: 12px;
    line-height: 24px;
    padding-left: 36px;
  }
  .checkbox > input + label::before {
    border-radius: 6px;
    width: 24px;
  }
}

@keyframes openModalPortrait {
  0% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes closeModalPortrait {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }
}
