.steps {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.5625vw;
  margin-bottom: 2.083333333333333vw;
}
.steps > ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.steps > ul > li {
  aspect-ratio: 1 / 1;
  background-color: var(--Bullet-Inactive);
  border-radius: 50%;
  display: block;
  margin-right: 1.041666666666667vw;
  width: 0.625vw;
  transition: background-color var(--move-speed) ease;
}
.steps > ul > li:last-child {
  margin-right: 0;
}
.steps > ul > li.active {
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
}
.skip {
  color: var(--Dark-Moonless-Mystery-60);
  margin-left: 1.666666666666667vw;
  opacity: 0;
  pointer-events: none;
  transition: color var(--hover-speed) ease, opacity var(--move-speed) ease;
}
.skip.visible {
  opacity: 1;
  pointer-events: auto;
}

@media (pointer: fine) {
  .skip:hover {
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
}

@media (orientation: portrait) {
  .steps {
    justify-content: center;
    height: 16px;
    margin-bottom: 0;
    width: 100%;
  }
  .steps > ul > li {
    margin-right: 16px;
    width: 8px;
  }
  .skip {
    font-size: 14px;
    position: absolute;
    right: 0;
  }
}
