.OverlayOpen {
  animation: openOverlay 0.5s ease-out forwards;
}

.OverlayClosed {
  animation: closeOverlay 0.35s ease-out forwards;
}

.ModalOpen {
  animation: openModal 0.5s ease-out forwards;
}

.ModalClosed {
  animation: closeModal 0.35s ease-out forwards;
}

@keyframes openOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.overlay {
  background: var(--Dark-Moonless-Mystery-10);
  backdrop-filter: blur(10px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.help {
  border-radius: 1.25vw;
  box-sizing: border-box;
  background: white;
  left: 50%;
  margin-left: -16.666666666666667vw;
  padding: 2.916666666666667vw 2.083333333333333vw 2.083333333333333vw
    2.083333333333333vw;
  position: fixed;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 33.333333333333333vw;
  z-index: 2200;
}
.close {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -2.5vw;
  width: 1.666666666666667vw;
}
.close > svg > path {
  stroke: var(--Icon-Grey-Icon-Grey-80);
  transition: stroke var(--hover-speed) ease;
}
.title {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 0.833333333333333vw;
}
.form {
  margin-top: 2.5vw;
}
.input {
  margin-top: 1.25vw;
  position: relative;
}
.input > label {
  background-color: white;
  color: var(--Dark-Moonless-Mystery-60);
  font-size: 0.729166666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  left: 1.041666666666667vw;
  padding: 0 0.208333333333333vw;
  position: absolute;
  top: 0;
  transform: translate3d(0, -50%, 0);
  transition: color var(--hover-speed) ease;
}
.input > input,
.input > textarea {
  border: none;
  border: 1px solid var(--Icon-Grey-Icon-Grey-60);
  border-radius: 0.729166666666667vw;
  background-image: none;
  background-color: transparent;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: var(--Dark-Moonless-Mystery-100);
  font-family: "Montserrat", sans-serif;
  font-size: 0.833333333333333vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  padding: 0 1.041666666666667vw;
  margin: 0;
  outline: 1px solid transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2.916666666666667vw;
  transition: border-color var(--hover-speed) ease,
    outline var(--hover-speed) ease;
}
.input > textarea {
  height: 6.354166666666667vw;
  padding: 0.9375vw 1.041666666666667vw;
  resize: none;
}
.input > input::placeholder,
.input > textarea::placeholder {
  color: var(--Dark-Moonless-Mystery-20);
}
.input > input.hasError,
.input > textarea.hasError {
  border-color: var(--Red);
  outline-color: var(--Red);
}
.input > input.hasError + label,
.input > textarea.hasError + label {
  color: var(--Red);
}
.input > input:focus,
.input > textarea:focus {
  border-color: var(--Blue-Elemental-Blue-Elemental-100);
  outline-color: var(--Blue-Elemental-Blue-Elemental-100);
}
.input > input:focus + label,
.input > textarea:focus + label {
  color: var(--Blue-Elemental-Blue-Elemental-100);
}
.input > input:-webkit-autofill,
.input > input:-webkit-autofill:hover,
.input > input:-webkit-autofill:focus,
.input > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.error {
  color: var(--Red);
  font-size: 0.729166666666667vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.416666666666667vw;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.333333333333333vw;
}
.buttons > * {
  min-width: 7.8125vw;
}

@media (pointer: fine) {
  .close:hover > svg > path {
    stroke: var(--Red);
  }
}

@media (orientation: portrait) {
  .ModalOpen {
    animation: openModalPortrait 0.5s ease-out forwards;
  }

  .ModalClosed {
    animation: closeModalPortrait 0.35s ease-out forwards;
  }

  .overlay {
    backdrop-filter: blur(5px);
  }
  .help {
    border-radius: 16px;
    bottom: 52px;
    left: 24px;
    margin-left: 0;
    padding: 32px 16px 16px 16px;
    right: 24px;
    top: auto;
    transform: translate3d(0, 0, 0);
    width: auto;
  }
  .close {
    top: -48px;
    width: 32px;
  }
  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .form {
    margin-top: 32px;
  }
  .input {
    margin-top: 24px;
  }
  .input > label {
    font-size: 14px;
    left: 20px;
    padding: 0 4px;
  }
  .input > input,
  .input > textarea {
    border-radius: 14px;
    font-size: 16px;
    padding: 0 20px;
    height: 56px;
  }
  .input > textarea {
    height: 122px;
    padding: 18px 20px;
  }
  .error {
    font-size: 14px;
    margin-top: 8px;
  }
  .buttons {
    margin-top: 48px;
  }
  .buttons > * {
    flex: 0 0 calc(50% - 8px);
    min-width: 0;
  }
}

@keyframes openModalPortrait {
  0% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes closeModalPortrait {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, calc(100% + 52px), 0);
  }
}
