.onboarding {
  display: block;
}

.screens {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% - 4.583333333333333vw);
  margin-top: 4.583333333333333vw;
  transition: transform var(--move-speed) ease;
}

.screens > div {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.screenContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}

.screenLeft {
  flex: 0 0 45.625vw;
  opacity: 0;
  position: relative;
  transition: opacity var(--appear-speed) ease;
  width: 45.625vw;
}

.screenRight {
  box-sizing: border-box;
  flex: 0 1 100%;
  padding: 0 7.291666666666667vw;
  opacity: 0;
  transform: translate3d(0, 50%, 0);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
}

.screenData {
  min-height: 17.291666666666667vw;
  position: relative;
  width: 100%;
}

.screenBottom {
  margin-top: 2.5vw;
}

.onboarding.active .screenLeft {
  opacity: 1;
}
.onboarding.active .screenRight {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.25s;
}

.onboarding.hide .screenLeft {
  opacity: 0;
  transition-delay: 0s;
}
.onboarding.hide .screenRight {
  opacity: 0;
  transform: translate3d(0, -50%, 0);
  transition-delay: 0s;
}

@media (orientation: portrait) {
  .onboarding {
    height: calc(100% - 61px);
    margin-top: 61px;
  }
  .steps {
    display: block;
    left: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity var(--appear-speed) ease;
  }
  .screens {
    height: 100%;
    margin-top: 0;
  }
  .screenContent {
    box-sizing: border-box;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    padding-top: 56px;
  }

  .screenLeft {
    flex: 0 0 48%;
    transform: none !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
  }

  .screenRight {
    box-sizing: border-box;
    flex: 0 0 52%;
    padding: 32px 0 0;
    transform: none !important;
    transition: opacity var(--appear-speed) ease,
      transform var(--appear-speed) ease;
  }

  .screenData {
    min-height: auto;
    height: calc(100% - 52px - 24px - 24px);
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
  }

  .screenBottom {
    display: none;
  }

  .screenBottom > button {
    width: 100%;
  }

  .backNext {
    box-sizing: border-box;
    display: block;
    margin-top: 24px;
    opacity: 0;
    position: relative;
    transition: opacity var(--appear-speed) ease;
    z-index: 2;
  }

  .backNext button {
    width: 100%;
  }

  .onboarding.active .steps {
    opacity: 1;
    transition-delay: 0.5s;
  }
  .onboarding.active .screenRight {
    transition-delay: 0.5s;
  }
  .onboarding.active .backNext {
    opacity: 1;
    transition-delay: 0.5s;
  }

  .onboarding.hide .steps {
    opacity: 0;
    transition-delay: 0s;
  }
  .onboarding.hide .screenRight {
    transition-delay: 0s;
  }
  .onboarding.hide .backNext {
    opacity: 0;
    transition-delay: 0s;
  }
}
