a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before {
  content: "";
  content: none;
}

q::after,
q::before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg {
  display: block;
  overflow: visible;
  position: relative;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

:root {
  --Light-Bg: #f6f7fc;
  --Dark-Moonless-Mystery-10: rgba(40, 46, 70, 0.1);
  --Dark-Moonless-Mystery-20: rgba(40, 46, 70, 0.2);
  --Dark-Moonless-Mystery-30: rgba(40, 46, 70, 0.3);
  --Dark-Moonless-Mystery-40: rgba(40, 46, 70, 0.4);
  --Dark-Moonless-Mystery-60: rgba(40, 46, 70, 0.6);
  --Dark-Moonless-Mystery-80: rgba(40, 46, 70, 0.8);
  --Dark-Moonless-Mystery-90: rgba(40, 46, 70, 0.9);
  --Dark-Moonless-Mystery-100: #282e46;
  --Aside-Button: radial-gradient(
    circle,
    rgba(80, 132, 233, 0.15) 0%,
    rgba(80, 132, 233, 0) 50%
  );
  --Button-hover: #365de4;
  --Mic-hover: #2567e9;
  --Bullet-Inactive: #d0dae7;
  --Blue-Elemental-Blue-Elemental-100: #5186ef;
  --Blue-Elemental-Blue-Elemental-80: rgba(81, 134, 239, 0.8);
  --Blue-Elemental-Blue-Elemental-10: rgba(81, 134, 239, 0.1);
  --Border: #cdd8e9;
  --Icon-Grey-Icon-Grey-0: rgba(188, 197, 210, 0);
  --Icon-Grey-Icon-Grey-20: rgba(188, 197, 210, 0.2);
  --Icon-Grey-Icon-Grey-40: rgba(188, 197, 210, 0.4);
  --Icon-Grey-Icon-Grey-60: rgba(188, 197, 210, 0.6);
  --Icon-Grey-Icon-Grey-80: rgba(188, 197, 210, 0.8);
  --Icon-Grey: #bcc5d2;
  --Grey-Grey-20: rgba(208, 217, 238, 0.2);
  --Grey-Grey-100: #d0d9ee;
  --Red: #ee3360;
  --White-White-40: rgba(255, 255, 255, 0.4);

  --hover-speed: 0.25s;
  --move-speed: 0.5s;
  --appear-speed: 1s;
}

html,
body {
  background-color: var(--Light-Bg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--Dark-Moonless-Mystery-80);
  font-family: "Montserrat", sans-serif;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  line-height: 1.6;
  overflow: hidden;
  width: 100%;
}

body {
  background-color: var(--Light-Bg);
  background-image: url(/public/back.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.layout {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}
.layout.active {
  pointer-events: auto;
}
.container {
  padding: 0 5.208333333333333vw;
}

h2 {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 2.916666666666667vw;
  font-style: normal;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 2.1875vw;
}

h3 {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 2.916666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1.25vw;
}

@media (orientation: portrait) {
  html,
  body {
    font-size: 16px;
    line-height: 1.5;
  }
  body {
    background-image: url(/public/portrait-back.png);
  }
  .container {
    padding: 0 24px;
  }
  h2 {
    font-size: 32px;
    font-style: normal;
    line-height: 1.15;
    margin-top: 28px;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 8px;
  }
}
