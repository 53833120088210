.buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
}

.buttons > button {
  border: 1px solid transparent;
  border-radius: 0.729166666666667vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color var(--hover-speed) ease,
    background-color var(--hover-speed) ease, color var(--hover-speed) ease,
    opacity var(--hover-speed) ease;
}

.buttons > button.prev {
  margin-left: -0.833333333333333vw;
  padding: 0 1.666666666666667vw 0 0.833333333333333vw;
  width: auto !important;
}
.buttons > button.prev.hidden {
  opacity: 0;
  pointer-events: none;
}

.buttons > button.next {
  border-color: var(--Icon-Grey-Icon-Grey-60);
  background-color: white;
  padding: 0 0.833333333333333vw 0 1.666666666666667vw;
  width: auto !important;
}

.buttons > button.next.disabled {
  border-color: var(--Icon-Grey-Icon-Grey-0);
  background-color: var(--Grey-Grey-20);
  color: var(--Dark-Moonless-Mystery-40);
  pointer-events: none;
}

.buttons > button > svg {
  flex: 0 0 2.916666666666667vw;
  display: block;
  width: 2.916666666666667vw;
}

.buttons > button > svg > path {
  stroke: var(--Icon-Grey);
  transition: stroke var(--hover-speed) ease;
}

.buttons > button.next.disabled > svg > path {
  stroke: var(--Dark-Moonless-Mystery-20);
}

.buttons > button > span {
  transform: translate3d(0, 0, 0);
  transition: transform var(--hover-speed) ease;
}

.buttons > button.prev > svg {
  margin-right: 1.25vw;
}

.buttons > button.next > svg {
  margin-left: 1.25vw;
}

@media (pointer: fine) {
  .buttons > button:hover > svg > path {
    stroke: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .buttons > button.prev:hover {
    /* border-color: var(--Icon-Grey-Icon-Grey-60); */
    /* background-color: rgba(255, 255, 255, 0.6); */
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .buttons > button.prev:hover > span {
    transform: translate3d(-10%, 0, 0);
  }
  .buttons > button.next:hover {
    background-color: rgba(255, 255, 255, 0.6);
    border-color: var(--Blue-Elemental-Blue-Elemental-100);
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
  .buttons > button.next:hover > span {
    transform: translate3d(10%, 0, 0);
  }
}

@media (orientation: portrait) {
  .buttons {
    font-size: 16px;
    height: 52px;
  }

  .buttons > button {
    border-radius: 12px;
  }

  .buttons > button.prev {
    margin-left: -12px;
    padding: 0 24px 0 12px;
  }

  .buttons > button.next {
    margin-right: -12px;
    padding: 0 12px 0 24px;
  }

  .buttons > button > svg {
    flex: 0 0 40px;
    width: 40px;
  }

  .buttons > button.prev > svg {
    margin-right: 16px;
  }

  .buttons > button.next > svg {
    margin-left: 16px;
  }
}
