.thank {
  text-align: center;
}
.thank img {
  aspect-ratio: 858 / 660;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 44.6875vw;
}

/* Animation Default State */
.thank img {
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}
.thank h2 {
  font-size: 3vw;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  max-width: 78vw;
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}

/* Appear Animation */
.thank.active img {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.5s;
}
.thank.active h2 {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1s;
}

@media (orientation: portrait) {
  .thank img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
  }
  .thank h2 {
    font-size: 32px;
    width: 824px;
    max-width: calc(100vw - 32px);
  }
}
