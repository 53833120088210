.quiz canvas {
  backface-visibility: hidden;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 59.166666666666667vw;
  transition: opacity var(--appear-speed);
}
.quiz.recording canvas {
  opacity: 1;
}

.quiz.active > footer {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.5s;
}

@media (orientation: portrait) {
  .quiz {
    height: calc(100% - 61px);
    margin-top: 61px;
  }

  .quiz canvas {
    left: 0;
    transform: translate3d(0, -37.5%, 0);
    width: 100%;
  }
}
