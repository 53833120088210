.layout404 {
  text-align: center;
}
.layout404 img {
  aspect-ratio: 616 / 440;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 54%;
}
.layout404 p {
  margin-top: 0.885416666666667vw;
}

/* Animation Default State */
.layout404 img {
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}
.layout404 h2 {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}
.layout404 p {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: opacity var(--appear-speed) ease,
    transform var(--appear-speed) ease;
}

/* Appear Animation */
.layout404.active img {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.5s;
}
.layout404.active h2 {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0.75s;
}
.layout404.active p {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 1.25s;
}

@media (orientation: portrait) {
  .layout404 img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
  }
  .layout404 p {
    margin-top: 17px;
  }
}
