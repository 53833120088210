.button {
  border-radius: 0.729166666666667vw;
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  box-sizing: border-box;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
  padding: 0 1.666666666666667vw;
  transition: background-color var(--hover-speed) ease,
    border-color var(--hover-speed) ease, color var(--hover-speed) ease;
}
.secondary {
  border: 1px solid var(--Icon-Grey-Icon-Grey-60);
  background-color: white;
  color: var(--Dark-Moonless-Mystery-80);
}
.small {
  border-radius: 0.729166666666667vw;
  font-size: 1.041666666666667vw;
  height: 3.333333333333333vw;
}
@media (pointer: fine) {
  .button:hover {
    background-color: var(--Button-hover);
  }
  .secondary:hover {
    background-color: white;
    border-color: var(--Blue-Elemental-Blue-Elemental-100);
    color: var(--Blue-Elemental-Blue-Elemental-100);
  }
}

@media (orientation: portrait) {
  .button,
  .small {
    border-radius: 12px;
    font-size: 16px;
    height: 52px;
    padding: 0 16px;
  }
}
