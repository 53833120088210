.terms {
  background-color: white;
  box-sizing: border-box;
  border-radius: 1.25vw;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  padding: 1.25vw 0.572916666666667vw 1.25vw 2.5vw;
  transform: translate3d(0, -50%, 0) scale(0.9);
  transition: opacity var(--move-speed) ease, transform var(--move-speed) ease;
  width: 45.625vw;
}
.overflow {
  box-sizing: border-box;
  height: calc(70vh);
  overflow: auto;
  padding: 1.666666666666667vw 1.927083333333333vw 1.666666666666667vw 0;
  position: relative;
}
.overflow::-webkit-scrollbar {
  width: 0.416666666666667vw;
}
.overflow::-webkit-scrollbar-track {
  background: var(--Icon-Grey-Icon-Grey-40);
  border-radius: 100px;
}
.overflow::-webkit-scrollbar-thumb {
  background: var(--Blue-Elemental-Blue-Elemental-100);
  border-radius: 100px;
}
.overflow::-webkit-scrollbar-thumb:hover {
  background: var(--Blue-Elemental-Blue-Elemental-80);
}
.overflow > * {
  font-size: 0.9375vw;
  line-height: 170%;
  margin-bottom: 1.25vw;
}
.overflow > *:last-child {
  margin-bottom: 0;
}
.overflow > h4 {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 1.666666666666667vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 1.666666666666667vw;
}
.overflow > h5 {
  color: var(--Dark-Moonless-Mystery-100);
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
  margin-top: 2.5vw;
}
.overflow > ul > li {
  margin-bottom: 0.416666666666667vw;
  padding-left: 1.25vw;
  position: relative;
}
.overflow > ul > li:last-child {
  margin-bottom: 0;
}
.overflow > ul > li::before {
  aspect-ratio: 1 / 1;
  background-color: var(--Blue-Elemental-Blue-Elemental-100);
  border-radius: 50%;
  content: "";
  left: 0;
  position: absolute;
  top: 0.71875vw;
  width: 0.3125vw;
}

.active.shown .terms {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, -50%, 0) scale(1);
  transition-delay: 0.15s;
}

@media (orientation: portrait) {
  .terms {
    box-sizing: border-box;
    border-radius: 16px;
    height: calc((100dvh - 61px - (20px + 16px + 20px)) * 0.48);
    margin: 0;
    padding: 12px 8px 12px 24px;
    top: 50%;
    width: 100%;
  }
  .overflow {
    height: 100%;
    padding: 12px 12px 12px 0;
  }
  .overflow::-webkit-scrollbar {
    width: 4px;
  }
  .overflow > * {
    font-size: 12px;
    margin-bottom: 14px;
  }
  .overflow > h4 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .overflow > h5 {
    font-size: 16px;
    margin-top: 32px;
  }
  .overflow > ul > li {
    margin-bottom: 4px;
    padding-left: 16px;
  }
  .overflow > ul > li::before {
    top: 9.2px;
    width: 4px;
  }
}
